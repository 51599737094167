<template>
  <div class="row">
    <div class="form-group col-12">
      <label for="name">{{ $t("Krstné meno") }} *</label>
      <input
        v-model="user.firstName"
        class="form-control"
        :placeholder="$t('Zadajte meno')"
        type="text"
        autocomplete="given-name"
      />
    </div>

    <div class="form-group col-12">
      <label for="surname">{{ $t("Priezvisko") }} *</label>
      <input
        v-model="user.lastName"
        class="form-control"
        :placeholder="$t('Zadajte priezvisko')"
        type="text"
        autocomplete="family-name"
      />
    </div>

    <div class="form-group col-12">
      <label for="emailAddress">{{ $t("Emailová adresa") }} *</label>
      <input
        v-model="user.email"
        class="form-control"
        :placeholder="$t('Zadajte email')"
        type="email"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: { required: true },
  },

  data() {
    return {
      schoolOptions: [
        { value: "Základná škola", text: "Základná škola" },
        { value: "Stredná škola", text: "Stredná škola" },
        { value: "Vysoká škola", text: "Vysoká škola" },
        { value: "Niesom študent", text: "Nechodím do školy" },
      ],
    };
  },
};
</script>
